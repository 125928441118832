.page-team-list {
  background-image: url('../images/undraw_hang_out_h9ud.svg');
  background-repeat: no-repeat;
  background-size: 115%;
  background-position: 49px -40px;
  @include media-breakpoint-up(sm) {
    background-size: 80%;
    background-position: 270px -40px;
  }
  @include media-breakpoint-up(md) {
    background-size: 65%;
    background-position: 400px -60px;
  }
  @include media-breakpoint-up(lg) {
    background-size: 50%;
    background-position: 100% -110px;
  }
}
