.main-menu {
  height: inherit;
  display: none;
  @include media-breakpoint-up(md) {
    display: block;
  }
  > ul {
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;
    > li {
      height: inherit;
      margin: 0;
      padding: 0;
      list-style: none;
      &:last-of-type {
        margin: 0;
      }
      > a {
        height: inherit;
        display: inline-block;
        padding: 0;
        font-size: 1rem;
        font-weight: 400;
        text-transform: uppercase;
        color: inherit;
        padding: 10px 14px 10px 14px;
        text-decoration: none;
        &:hover {
          background: $primary;
          color: #fff;
          text-decoration: none;
        }
      }
    }
    > li.active {
      > a {
        background: $primary;
        text-decoration: none;
        color: #fff;
        &:hover {
          text-decoration: none;
          background: $primary;
          transition: all 225ms ease-in 0s;
          color: #fff;
        }
        span {
          display: block;
        }
      }
      ul.sub-menu.hidden {
        display: flex;
        opacity: 0;
        transition: all 225ms ease-in 0s;
        z-index: 1;
      }
      ul.sub-menu {
        display: flex;
        opacity: 0.8;
        transition: all 225ms ease-in 0s;
      }
    }
  }
}
