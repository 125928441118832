.service-summary {
  padding-top: 15px;
  max-width: 420px;
  .service-image {
    margin: 0 10px 10px 10px;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .service-content {
    flex: 1;
    .service-title {
      font-family: $font-family-heading;
      font-size: 1.6rem;
    }
    p {
      color: #212529;
    }
    p:hover {
      text-decoration: none !important;
    }
  }
}
