nav.breadcrumbs.bootstrap ol {
    margin-left: 0
 }

 nav.breadcrumbs.bootstrap .breadcrumb {
    font-size: 0.8rem;
    padding: 0.75rem 0rem;
    margin-bottom: 1rem;
    list-style: none;
    /* background-color: #f1f2ed; @at-root*/
    border-radius: 0.25rem;
 }

 nav.breadcrumbs.bootstrap .breadcrumb::after {
    content: "";
    display: table;
    clear: both;
 }

 nav.breadcrumbs.bootstrap .breadcrumb-item {
    float: left;
 }

 nav.breadcrumbs.bootstrap .breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: #818a91;
    content: "/";
 }

 nav.breadcrumbs.bootstrap .breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: underline;
 }

 nav.breadcrumbs.bootstrap .breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: none;
 }

 nav.breadcrumbs.bootstrap .breadcrumb-item-active {
    color: #818a91;
 }

 nav.breadcrumbs.bootstrap .breadcrumb .icon {
    display: inline-block;
 }

 nav.breadcrumbs.bootstrap .breadcrumb .icon svg {
    height: 0.8rem;
    width: 0.8rem;
 }
/* home başlangıç */

@media (min-width: 768px) and (max-width: 992px)
{
   div.intro {
      padding-top: 120px;
   }

   div.intro div.container h1 {
      font-size: 2.7rem;
   }

   div.intro div.container p {
      width: 50%;
      font-size: 1rem;
   }
}

@media (min-width: 576px)
{
   div.intro {
      padding-top: 120px;
   }

   div.intro div.container h1 {
      font-size: 2.1rem;
   }

   div.intro div.container p {
      width: 50%;
      font-size: 1rem;
   }
}

@media (max-width: 575px)
{
   div.intro {
      padding-top: 120px;
   }

   div.intro div.container h1 {
      font-size: 1.9rem;
   }

   div.intro div.container p {
      width: 50%;
      font-size: 0.9rem;
   }
}

/* home bitiş */